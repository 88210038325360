import { BrowserRouter as AppRouter, Route, Switch } from 'react-router-dom'

function App() {
  return (
    <AppRouter basename="">
      <Switch>
        <Route path="*" render={() => window.location = "https://www.byscenen.no/"} />
      </Switch>
    </AppRouter>

  );
}

export default App;
